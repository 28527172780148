import * as React from 'react';
import { Link } from 'gatsby';
import { Container, Embed, Grid, Label, Message } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUsFormModal } from '../../../components/contact_us_form';

function DistributedAcousticSensing() {
  return (
    <>
      <H1>Simulating Marine Data for Distributed Acoustic Sensing (DAS)</H1>
      <CenteredImage>
        <StaticImage
          src="./resources/cable.png"
          alt="Fiber-optic cables can act as seismometers"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <p>
        Distributed Acoustic Sensing (DAS) is a novel data collection modality
        that measures the scattering of laser pulses traveling along fiber-optic
        cables buried within, or attached to the surface of, bulk objects. These
        scattering events can be used as a proxy for the axial strain along the
        cable, such as that imparted by propagating acoustic and/or elastic
        waves. The inferred strain measurements can then be used, for instance,
        to monitor the state of some subsurface resource (e.g. a geothermal
        field), to monitor the health of critical infrastructure, and,
        ultimately, to infer a time-dependent 3-D model of the bulk itself.
        Major advantages over traditional data collection modalities, which are
        primarily based on either inertial or piezoelectric point sensors,
        include the low cost of the fiber itself, its resilience in harsh
        environments and, perhaps most excitingly, its ability to use existing
        telecommunications infrastructure as a measurement device.
      </p>
      <p>
        DAS deployments are commonly done on scales larger than those amenable
        to laboratory testing, and because of this scientists and engineers
        often turn to numerical simulations in order to understand the signals
        they record. Salvus allows for the simple and efficient simulation of
        acoustic and elastic waves in many scenarios commonly probed using DAS
        methods, and **natively supports the output of strain at arbitrary
        points, surfaces, and volumes**, which is critical when comparing to
        data. In this blog post we'll take a look at a specific scenario of
        interest to DAS practitioners: the simulation of seismic waves in the
        vicinity of the MARS cable deployed in Monterey Bay, California.
      </p>
      <H2>MARS DAS survey</H2>
      <p>
        The Monterey Accelerated Research System (MARS) cable is a 52-km-long
        fiber-optic cable connecting a subsea "science node", host to several
        different oceanographic experiments, to Moss Landing in Monterey Bay,
        California. Seismologists have recently become interested (Lindsey,
        2019) in using the cable to record seismic events using principles based
        on DAS. The physical environment has the potential to be challenging to
        simulate. Factors such as:
      </p>
      <ul>
        <li>3-D surface topography</li>
        <li>3-D ocean bathymetry</li>
        <li>The fluid/solid interaction at the seafloor, and</li>
        <li>seismic reverberations in the ocean</li>
      </ul>
      <p>
        all emerge to complicate the situation. Salvus, however, has the ability
        to automatically include all of these factors, and to produce an
        accurate and efficient synthetic dataset with minimal user effort and
        compute resources.
      </p>

      <H2>Topography and bathymetry</H2>
      <p>
        To deal with the complexities of Earth's surface, Mondaic hosts a few{' '}
        <a
          href="https://mondaic.com/docs/data/seismology/global_topography_data#preprocessed-data-sets-for-download"
          target="_self"
        >
          pre-processed topography and bathymetry datasets
        </a>{' '}
        that are{' '}
        <a
          href="https://mondaic.com/docs/data/seismology/global_topography_data#data-sources"
          target="_self"
        >
          derived from several sources
        </a>
        . These are valid for the entire globe, and interact natively with
        Salvus' bundled mesh generation software. **As a result, users can
        quickly generate geographically accurate meshes for any region on
        Earth**. Ocean layers, along with the ocean-subsurface coupling
        condition, are applied automatically. These datasets support topographic
        resolutions of up to 1.8km, and as such are sufficient for most
        applications in medium- to long-period seismology. If higher resolution
        topography is desired, Salvus can automatically download, read, and mesh
        it using the{' '}
        <a href="https://www.gmrt.org" target="_blank">
          GMRT project
        </a>
        's web interface. This feature, along with the usage of custom
        user-defined topography, will be explored in a future post.
      </p>

      <CenteredImage>
        <StaticImage
          src="./resources/topo_lmax_64_and_lmax_1024.png"
          alt="Salvus supports global-scale topographic models with variety of resolutions"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <H2>Running a simulation</H2>
      <CenteredImage>
        <StaticImage
          src="./resources/thumbnail.png"
          alt="DAS simulation"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>
      <p>
        The animation below shows an onshore event acting as the source of a
        seismic wavefield in the vicinity of the MARS cable, with topography and
        bathymetry automatically extracted from the datasets described above.
        Immediately apparent is the smooth shoreline of Monterey Bay, and the
        presence of the fluid ocean (deep blue) in contrast to the onshore
        geography. As a source mechanism, a vertical force injected by a Ricker
        Wavelet -- one of{' '}
        <a
          href="https://mondaic.com/docs/references/python_api/salvus/flow/simple_config/stf"
          target="_self"
        >
          several built-in source wavelets
        </a>{' '}
        -- was chosen.
      </p>

      <Embed
        id="465477132"
        source="vimeo"
        autoplay={true}
        placeholder="https://vumbnail.com/465477132.jpg"
        iframe={{
          allowFullScreen: true,
        }}
      />

      {/* [![Video autoplays here?]()](xhttps://vimeo.com/465477132/3a857999e9 'Mondaic') */}

      <p>
        We see the elastic displacement (in orange / purple) at the surface as
        it propagates away from the hypocenter. As the wavefield enters the
        ocean, some energy is dumped into an oceanic reverberation, and a
        prominent Scholte wave is formed. This Scholte wave continues to
        propagate along the seafloor boundary, falling behind the elastic
        surface wave due to its slower speed. Looking at the shotgather below,
        we can also see these characteristics as they appear to the MARS cable.
        Plotted here, clockwise from top left, are the xx, yy, zz, and xy
        components of strain respectively. As the entire strain tensor is
        available for output at arbitrary point locations, rotations and
        transformations into different user-specified reference frames are a
        breeze.
      </p>

      <CenteredImage>
        <StaticImage
          src="./resources/strain_shots.png"
          alt="Strain shotgathers"
          quality={100}
          placeholder="tracedSVG"
        />
      </CenteredImage>

      <p>
        The code required to setup the problem from scratch, run a 3-D
        simulation, output the quantities used in the animation, and visualize
        the strain output is completely described by a handful of Python cells
        in a Jupyter notebook. Input file generation and data collection is
        handled entirely by SalvusFlow, and no manual interaction with the
        compute resource is required. In all, **this 3-D simulation took a few
        minutes on a single NVIDIA GeForce RTX 2060 GPU**.
      </p>
      <p>
        The example given in this post can be readily applied to any other
        region across the globe, and is not limited to the simulation of DAS
        data. The same flexibility we saw with strain measurements is available
        with elastic displacement, velocity, acceleration, or stress, or
        acoustic particle velocity or pressure, along with several other derived
        output quantities. For accuracy benchmarks, please see some{' '}
        <a
          href="https://mondaic.com/docs/examples/integration_tests/analytic_tests/coupled_2d"
          target="_self"
        >
          comparisons with semi-analytic solutions
        </a>
        , and always feel free to take a look at this{' '}
        <a
          href="https://academic.oup.com/gji/article/216/3/1675/5174970"
          target="_blank"
        >
          open-access paper
        </a>{' '}
        describing Salvus' design.
      </p>

      <H2>Benefits of Salvus for modeling DAS surveys</H2>
      <p>
        <ul>
          <li>Accurate placement of DAS lines in complex geometries.</li>
          <li>True solid-fluid multiphysics coupling.</li>
          <li>
            (Visco-)elastic / acoustic, with arbitrary elastic anisotropy.
          </li>
          <li>Performance and scalability in 2-D and 3-D on CPUs and GPUs.</li>
          <li>
            Modern Python-based user interface and extendable research platform.
          </li>
        </ul>
      </p>

      <Grid style={{ marginTop: '5ex' }}>
        <Grid.Row centered>
          The Salvus software suite is Mondaic's flagship code to run
          simulations like the ones described above
        </Grid.Row>
        <Grid.Row centered>... and many more!</Grid.Row>
        <Grid.Row centered>
          <ContactUsFormModal />
        </Grid.Row>
      </Grid>
    </>
  );
}

export default withLayout(DistributedAcousticSensing);
